import request from '../utils/request'


export const warning_list=(company,imei,phone,name,gender,user_job,warning_type,branch1,branch2,start_time,end_time,pagenum,pagesize)=>{
    return request({
        url:'/admin/warning_list',
        method:'post',
        data:{
            company,
            imei,
            phone,
            name,
            gender,
            user_job,
            warning_type,
            branch1,
            branch2,
            start_time,
            end_time,
            pagenum,
            pagesize
        }
    })
}
export const emotion_list=(company,name,job,branch1,branch2,branch3,branch4,date,enddate,pagenum,pagesize)=>{
    return request({
        url:'/admin/emotion_list',
        method:'post',
        data:{
            company,
            name,
            job,
            branch1,
            branch2,
            branch3,
            branch4,
            date,
			enddate,
			pagenum,
			pagesize,
        }
    })
}
export const person_day=(imei,date)=>{
    return request({
        url:'/admin/person_day',
        method:'post',
        data:{
            imei,
            date
        }
    })
}