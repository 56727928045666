<template>
	<div class="container">
		<el-breadcrumb separator="/">
			<el-breadcrumb-item>管理后台</el-breadcrumb-item>
			<el-breadcrumb-item>情绪识别</el-breadcrumb-item>
			<el-breadcrumb-item>预警记录</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<el-row>
				<el-col :span="24">
					<el-form :inline="true">
						<el-form-item>
							<el-input size="small" placeholder="IMEI" v-model="searchImei"></el-input>
						</el-form-item>
						<el-form-item>
						</el-form-item>
						<el-form-item>
							<el-input size="small" placeholder="电话" v-model="searchPhone"></el-input>
						</el-form-item>
						<el-form-item>
							<el-input size="small" placeholder="姓名" v-model="searchName"></el-input>
						</el-form-item>
						<el-form-item>
							<el-select size="small" v-model="searchGender" placeholder="性别">
								<el-option label="男" value="男"></el-option>
								<el-option label="女" value="女"></el-option>
							</el-select>
						</el-form-item>
						<!--                        <el-form-item>
                            <el-input size="small" placeholder="专业" v-model="searchMajor"></el-input>
                        </el-form-item> -->
						<el-form-item>
							<el-select size="small" v-model="searchType" placeholder="分类">
								<el-option label="情绪" value="emotion"></el-option>
								<el-option label="压力" value="stress"></el-option>
								<el-option label="疲劳" value="tired"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-date-picker size="small" v-model="searchDate" @change="timechange" type="daterange"
								unlink-panels format="yyyy 年 MM 月 dd 日" value-format="timestamp" range-separator="至"
								start-placeholder="开始日期" end-placeholder="结束日期">
							</el-date-picker>
						</el-form-item>
						<el-form-item>
							<el-select v-model="searchDepartment1" size="small" placeholder="单位"
								:disabled="branchArr[0] ? true:false" @change="choiceA">
								<el-option v-for="item in department1List" :key="item.branch" :label="item.branch"
									:value="item.branch">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-select v-model="searchDepartment2" v-if="department2List.length>0" size="small"
								placeholder="班组" :disabled="branchArr[1] ? true:false">
								<el-option v-for="item in department2List" :key="item.branch" :label="item.branch"
									:value="item.branch">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" size="small" @click="search">查询</el-button>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" size="small" @click="reset">重置</el-button>
						</el-form-item>
						<el-form-item>
							<el-button type="success" size="small" v-if="total>0" @click="exportData">导出数据</el-button>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<el-table :data="warningData" border stripe style="width: 100%">
				<el-table-column min-width="130px" prop="imei" label="IMEI">
				</el-table-column>
				<el-table-column prop="phone" label="电话">
				</el-table-column>
				<el-table-column prop="nickname" label="姓名">
				</el-table-column>
				<el-table-column prop="gender" label="性别">
				</el-table-column>
				<el-table-column prop="birthday" label="年龄">
					<template slot-scope="scope">
						<el-tag>{{formatBirthday(scope.row.birthday)}}</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="branch_1" label="单位">
				</el-table-column>
<!-- 				<el-table-column prop="branch_2" label="班组">
					<template slot-scope="scope">
						<div v-if="scope.row.branch_2!='undefined'&&scope.row.branch_2!='null'">{{scope.row.branch_2}}
						</div>
						<div v-else></div>
					</template>
				</el-table-column> -->
				<!--                <el-table-column
                    prop="job"
                    label="专业">
                </el-table-column> -->
				<el-table-column prop="warning_type" label="分类">
					<template slot-scope="scope">
						{{formatwarningType(scope.row.warning_type)}}
					</template>
				</el-table-column>
				<el-table-column prop="warning_content" label="内容">
				</el-table-column>
				<el-table-column prop="warning_time" label="预警时间">
					<template slot-scope="scope">
						{{formatTimestamp(scope.row.warning_time)}}
					</template>
				</el-table-column>
			</el-table>
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagenum"
				:page-sizes="[5,10,15,20]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper"
				:total="total">
			</el-pagination>
		</el-card>
	</div>
</template>
<script>
	import moment from 'moment';
	import XLSX from "xlsx";
	import {
		department_list
	} from '../api/department'
	import {
		warning_list
	} from '../api/emotion'
	import server_url from '../utils/baseUrl'
	import {
		bian_log
	} from '../api/log'
	export default {
		data() {
			return {
				company: '',
				companyData: [],
				branch_content: '',
				branch_level: '',
				pagenum: 1,
				pagesize: 10,
				total: 0,
				searchImei: '',
				searchPhone: '',
				searchName: '',
				searchGender: '',
				searchMajor: '',
				searchType: '',
				searchDate: '',
				warningData: [],
				branchArr: [],
				department1List: [],
				department2List: [],
				searchDepartment1: null,
				searchDepartment2: null,
				publicUrl: server_url,
				allWarn: []
			};
		},
		computed: {
			startTime() {
				return this.searchDate[0] ? this.searchDate[0] : ''
			},
			endTime() {
				return this.searchDate[1] ? this.searchDate[1] : ''
			}
		},
		methods: {
			timechange() {
				this.searchDate[1] += 86399990;
			},
			formatBirthday(timeStr) {
				if (!timeStr) {
					return 0;
				}
				if (timeStr.length > 5) {
					let timestamp = moment(timeStr + ' 00:00:00')
					let nowTime = moment().valueOf()
					let birthday = moment(timestamp).valueOf()
					let nowYear = new Date(nowTime).getFullYear()
					let birthdayYear = new Date(birthday).getFullYear()
					return parseInt(nowYear - birthdayYear)
				} else {
					return 0;
				}

			},
			formatTimestamp(timestamp) {
				return moment(timestamp).format('YYYY-MM-DD HH:mm:ss')
			},
			formatwarningType(str){
				if(str=='stress') return "压力";
				if(str=='tired') return "疲劳";
				if(str=='emotion') return "情绪";
				return "";
			},
			departmentList() {
				department_list(this.company, this.searchDepartment1, this.searchDepartment2).then(res => {
					if (res.status != 200) {
						return this.$message.error('获取部门列表失败')
					}
					let branch = res.data.branch;
					branch.forEach(item => {
						item.children = JSON.parse(item.children)
					})
					this.companyData = branch;
					this.department1List = branch;
					//管理员部门查询筛选权限
					let branch_content = sessionStorage.getItem('branch-content')
					let branch_level = sessionStorage.getItem('level')
					let branchArr = [];
					if (branch_level == 1) {
						this.branchArr = []
					} else {
						branchArr = branch_content.split('|');
						this.branchArr = branchArr;
					}
					if (branchArr[0]) {
						this.searchDepartment1 = branchArr[0]
						this.department1List.forEach(item => {
							if (item.branch == this.searchDepartment1) {
								this.department2List = item.children
							}
						})
					}
					this.warningList()
				}).catch(err => {})
			},
			warningList() {
				const loading = this.$loading({
					lock: true,
					text: '请稍等,数据加载中...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				warning_list(this.company, this.searchImei, this.searchPhone, this.searchName, this.searchGender, this
					.searchMajor, this.searchType, this.searchDepartment1, this.searchDepartment2, this.startTime, this
					.endTime, this.pagenum, this.pagesize).then(res => {
					if (res.status != 200) {
						loading.close();
						return this.$message.error('预警数据获取失败')
					}
					this.warningData = res.data.warningData;
					this.total = res.data.total;
					this.allWarn = res.data.alldata;
					loading.close();
				}).catch(err => {
					loading.close()
				})
			},
			choiceA() {
				this.searchDepartment2 = null
				this.department2List = []
				this.department1List.forEach(item => {
					if (item.branch == this.searchDepartment1) {
						this.department2List = item.children
					}
				})
			},
			handleSizeChange(newSize) {
				this.pagesize = newSize;
				this.warningList()
			},
			handleCurrentChange(newPage) {
				this.pagenum = newPage;
				this.warningList()
			},
			search() {
				this.warningList()
			},
			reset() {
				this.searchImei = '';
				this.searchPhone = '';
				this.searchName = '';
				this.searchGender = '';
				this.searchMajor = '';
				this.searchType = '';
				this.searchDate = '';
				this.pagenum = 1;
				if (this.branchArr.length == 0) {
					this.searchDepartment1 = null
					this.searchDepartment2 = null
					this.department2List = []
				}
				if (this.branchArr.length == 1) {
					this.searchDepartment2 = null
				}
				this.warningList()
			},
			exportData() {
				let p = this.allWarn;
				let arrayData = [
					['IMEI', '电话', '姓名', '性别', '年龄', '单位', '班组', '分类', '内容', '预警时间']
				];
				for (let i in p) {
					let row = [
						p[i].imei, p[i].phone, p[i].nickname, p[i].gender,
						this.formatBirthday(p[i].birthday || ''),
						p[i].branch_1, p[i].branch_2, p[i].warning_type, p[i].warning_content, this.formatTimestamp(p[
							i].warning_time)
					];
					arrayData.push(row)
				}
				// 将数据转成workSheet
				let arrayWorkSheet = XLSX.utils.aoa_to_sheet(arrayData);
				// let jsonWorkSheet = xlsx.utils.json_to_sheet(jsonData);
				// 构造workBook
				let workBook = {
					SheetNames: ['Sheet1'],
					Sheets: {
						'Sheet1': arrayWorkSheet
					},
				};
				let worksheet = workBook.Sheets['Sheet1'];
				// 将workBook写入文件
				XLSX.writeFile(workBook, "员工预警数据.xlsx");
				bian_log(`导出员工预警数据`)
			}
		},
		created() {
			let company = sessionStorage.getItem('company')
			this.company = company;
			this.branch_content = sessionStorage.getItem('branch-content');
			this.branch_level = sessionStorage.getItem('level');
			this.departmentList();
		},
	};
</script>
<style lang='less' scoped>
</style>
